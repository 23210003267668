import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const element = $(this.element);
    element.removeClass("d-none");
    $(".elon-gif").removeClass("d-block").addClass("d-none");
    // $(element).keyup(function () {
    //   if (this.validateVin(element.val())) {
    //     element.removeClass("is-invalid");
    //   } else {
    //     element.addClass("is-invalid");
    //   }
    // });
  }

  formValidate(e) {
    let errors = false;
    $(e.target)
      .find("input.vin")
      .each(function () {
        if ($(this).hasClass("is-invalid")) {
          errors = true;
        }
      });
    if (errors == true) {
      e.preventDefault();
      setTimeout(function () {
        let elem = $(".form-submit");
        Rails.enableElement($(".form-submit")[0]);
        elem.html(elem.data("originalMsg"));
      }, 100);

      return false;
      // e.target.reportValidity();
    }
    const makers = $(e.target)
      .find(".vin")
      .map((i, v) => v.value.substring(0, 3))
      .get();
    if (makers.indexOf("5YJ") > -1) {
      $("form").removeClass("d-block").addClass("d-none");
      $(".elon-gif").addClass("d-block").removeClass("d-none");
    }
    return true;
  }

  validateVin(e) {
    const element = $(e.target);
    const value = element.val().toUpperCase().trim();
    element.val(value);
    if (this.validate(value)) {
      element.removeClass("is-invalid");
    } else {
      element.addClass("is-invalid");
    }
  }

  validate(vin) {
    if (vin[0] == "z" || vin[0] == "Z") return true;
    if (vin.length !== 17) return false;
    return this.get_check_digit(vin) === vin[8];
  }

  transliterate(c) {
    return "0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ".indexOf(c) % 10;
  }

  get_check_digit(vin) {
    var map = "0123456789X";
    var weights = "8765432X098765432";
    var sum = 0;
    for (var i = 0; i < 17; ++i)
      sum += this.transliterate(vin[i]) * map.indexOf(weights[i]);
    return map[sum % 11];
  }
}
